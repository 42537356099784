import axios from "axios";
import {handleGlobally} from "../utils/errorUtils";

const URL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: URL,
});

axiosInstance.interceptors.request.use(
    (config: any) => {
      const token = localStorage.getItem('auth_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      handleGlobally(error);
      return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      handleGlobally(error);
      return Promise.reject(error);
    }
);

export {
  axiosInstance
};